<template>
  <div class="main-page deep-detection">
    <custom-header></custom-header>
    <div class="desc">
      <h4>深度合成智能检测</h4>
      <p>提供包括PS篡改检测、深度合成检测、特定人物伪造检测、特定人物丑化检测、伪造溯源等技术鉴定和解决方案</p>
    </div>
    <van-tabs v-model="activeTab" @change="changeTab" class="custom-headers-com" swipeable v-show="!chooseShow">
      <van-tab name="1">
        <template #title>
          <div class="tab-headers">图像检测</div>
        </template>
        <div class="detection-inner">
          <div class="image-box">
            <div class="img-cover">
              <van-image fit="contain" :src="(resultData['1'] && resultData['1'].filename) ? resultData['1'].filename : imgUrl" lazy-load class="main-img">
                <template v-slot:loading>
                  <van-loading class="fs-80 lazy-loading" color="#007BFF"/>
                </template>
              </van-image>
            </div>
            <div class="qr-scanner" v-if="imgLoading">
              <div class="box">
                <div class="line"></div>
              </div>
            </div>
            <!-- <img :src="resultData['1'].score > 50 ? shamImg : realImg" alt="" class="result-img" v-if="resultData['1']"> -->
          </div>
          <ul class="tip-list">
            <li class="heat-block">
              <p class="name"><span>热力区</span></p>
              <p class="tip-desc">深度伪造区域</p>
            </li>
            <li class="red-block">
              <p class="name"><span>红色框</span></p>
              <p class="tip-desc">PS篡改区域</p>
            </li>
          </ul>
          <div class="result-panel" :class="resultData['1'] ? '' : 'hidden'">
            <h4>检测结果</h4>
            <div class="result-box-border"></div>
            <div class="result-inner">
              <div v-if="resultData['1']" class="result-box" :class="resultData['1'].score > 50 ? 'danger' : ''">{{resultData['1'].score > 50 ? '图像被篡改' : '图像无篡改'}}</div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab name="2">
        <template #title>
          <div class="tab-headers">视频检测</div>
        </template>
        <div class="detection-inner">
          <div class="image-box">
            <video :src="(resultData['2'] && resultData['2'].filename) ? resultData['2'].filename : videoUrl" controls autoplay loop x5-playsinline="" playsinline="" webkit-playsinline="" x5-video-player-type="h5" x5-video-player-fullscreen="" x5-video-orientation="portraint" class="video">您的浏览器不支持 video 标签。</video>
            <div class="qr-scanner" v-if="videoLoading">
              <div class="box">
                <div class="line"></div>
              </div>
            </div>
          </div>
          <ul class="tip-list">
            <li class="real-block">
              <p class="name"><span>绿色框</span></p>
              <p class="tip-desc">真实的人脸</p>
            </li>
            <li class="red-block">
              <p class="name"><span>红色框</span></p>
              <p class="tip-desc">篡改的人脸</p>
            </li>
            <li class="percent-block">
              <p class="name"><span>百分比数字</span></p>
              <p class="tip-desc">该人脸篡改的概率</p>
            </li>
          </ul>
          <div class="result-panel" :class="resultData['2'] ? '' : 'hidden'">
            <h4>检测结果</h4>
            <div class="result-box-border"></div>
            <div class="result-inner">
              <div v-if="resultData['2']" class="result-box" :class="resultData['2'].score > 50 ? 'danger' : ''">{{resultData['2'].content}}</div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    
    <van-button type="info" class="choose-btn" :disabled="imgLoading" @click="chooseExample" v-if="activeTab == 1">选择图片</van-button>
    <van-button type="info" class="choose-btn" :disabled="videoLoading" @click="chooseExample" v-else>选择视频</van-button>
    <custom-footer></custom-footer>

    <choose-example v-if="chooseShow" :dataList="exampleDataList" @goBack="hideExample" @onDetect="onDetect" :type="activeTab == 1 ? 'deepImg' : 'video'"></choose-example>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import ChooseExample from "@/components/ChooseExample.vue";

import { DATA } from '@/remote';
export default {
  name: "deep-detection",
  components: {
    "custom-header": CustomHeader,
    "custom-footer": CustomFooter,
    "choose-example": ChooseExample,
  },
  data(){
    return {
      activeTab: "1",
      imgUrl: '',
      videoUrl: '',
      // realImg: require('@/assets/img/real.png'),
      // shamImg: require('@/assets/img/sham.png'),
      imgLoading: false,
      videoLoading: false,
      rateValue: 0,
      chooseShow: false,
      exampleObj: {},
      resultData: {
        1: '',
        2: ''
      },
      exampleDataList: [],
    };
  },
  mounted() {
    this.getDemo(1);
    this.getVideoDemo(2);
  },
  methods: {
    // 切换tab
    changeTab(value) {
      this.activeTab = value;
      if (!this.resultData[value]) {
        if (value == 1) {
          !this.imgLoading && this.imageCheck(this.imgUrl);
        } else {
          !this.videoLoading && this.videoCheck(this.videoUrl);
        }
      }
    },

    getDemo(fileType) {
      DATA.getDemo({fileType}).then(res => {
        if (res.code == 200) {
          this.exampleObj[fileType] = res.data || [];
          let fileUrl = (res.data || [])[0]
          this.imgUrl = fileUrl;
          this.imageCheck(fileUrl);
        }
      })
    },

    getVideoDemo(fileType) {
      DATA.getVideoDemo().then(res => {
        if (res.code == 200) {
          this.exampleObj[fileType] = res.data || [];
          this.videoUrl = (res.data || [])[0].videoUrl;
        }
      })
    },

    // 图像检测
    imageCheck(fileUrl) {
      this.imgLoading = true;
      this.resultData['1'] = '';
      DATA.imageCheck({fileUrl}).then(res => {
        if (res.code == 200) {
          this.imgLoading = false;
          this.resultData['1'] = res.data || {};
          this.rateValue = 5 - parseInt((res.data || {}).score / 20);
        }
        this.imgLoading = false;
      })
    },

    // 视频检测
    videoCheck(fileUrl) {
      this.videoLoading = true;
      this.resultData['2'] = '';
      DATA.videoCheck({fileUrl}).then(res => {
        if (res.code == 200) {
          this.videoLoading = false;
          this.resultData['2'] = res.data || {};
        }
        this.videoLoading = false;
      })
    },

    // 选择示例
    chooseExample() {
      this.exampleDataList = this.exampleObj[this.activeTab];
      this.chooseShow = true;
    },

    hideExample() {
      this.chooseShow = false;
    },

    onDetect(fileUrl) {
      this.chooseShow = false;
      if (this.activeTab == 1) {
        this.imgUrl = fileUrl;
        this.imageCheck(fileUrl);
      } else {
        this.videoUrl = fileUrl;
        this.videoCheck(fileUrl);
      }
    },
  }
};
</script>

<style lang="less">
  @import '../../assets/less/deepDetection';
</style>
